<template>
  <v-app>
    <div>
      <v-alert
        v-model="alert"
        border="left"
        dark
        dismissible
        class="alert"
        :class="{
          'alert-error': alertType == 'error',
          'alert-success': alertType == 'success'
        }"
      >
        {{ alertMessage }}
      </v-alert>
    </div>
    <div class="row">
      <div class="col-md-6 ml-auto mr-auto">
        <div class="mb-8 mt-8 text-center">
          <h1>Leave Request</h1>
        </div>
        <v-form ref="leaveRequest" v-model="valid">
          <v-row no-gutters>
            <v-col :class="personnel.length > 0 ? 'mb-6': 'mb-6'">
              <v-btn :disabled="data.length > 0" class="btn-style justify-start text-style" @click.prevent="perSearch = true"  width="100%" height="38" outlined >  <v-icon left>mdi-magnify</v-icon> {{formData.personnelName == "" ? 'Personnel Name' : formData.personnelName}}</v-btn>
            </v-col>
            <v-col>
              <SelectField
                :items="leaveTypes"
                v-model="formData.leaveType"
                :label="'Leave Type'"
                :itemText="'name'"
                :itemValue="'id'"
                :returnObject="true"
              />
            </v-col>
             <v-col>
              <TextField
                v-model="days"
                :label="'Annual Leave Due'"
                :disable="true"
              />
            </v-col>
             <v-col>
              <TextField
                v-model="formData.days"
                :label="'Leave Days'"
              />
            </v-col>

            <v-col cols="6" class="pr-1">
               <v-text-field
                v-model="formData.startDate"
                outlined
                label="Start Date"
                @blur="startDateSelected"
                dense
                type="date"
               />
            </v-col>
            <v-col  cols="6" class="pl-1">
              <TextField
                v-model="formData.resumptionDate"
                :disable="true"
                :label="'Resumption Date'"
              />
            </v-col>
            <v-col>
              <TextField
                v-model="formData.comment"
                :label="'Comment'"
              />
            </v-col>
            <v-col cols="12" class="d-flex justify-end">
              <Button
                :label="'Add'"
                :btnType="'Submit'"
                @onClick="add"
                :disabled="!valid"
                :isLoading="isSaving"
              />
            </v-col>
          </v-row>
        </v-form>
      </div>
    </div>
    <v-row>
      <div class="col-md-10 col-sm-12 ml-auto mr-auto">
        <v-col v-if="request.length > 0" class="mb-6">
        <v-data-table :headers="requestHeader" :items="request">
          <template v-slot:item.comments="{ item }">
            <input type="text" class="input" v-model="item.comment" />
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon small @click="removeRequest(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
      </div>
    </v-row>

    <!-- Personnel Search Modal -->
    <Dialog ref="personnelSearch" :title="'Personnel Search'" :width="600">
      <div v-if="personnelSearchLoading" class="text-center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <v-row v-else>
        <v-col cols="12">
          <v-text-field
            v-model="search"
            outlined
            label="Search"
            @blur="personnelSearchFunction"
            dense
          />
        </v-col>
        <v-col v-if="formData.personnelName !== ''" cols="12 text-left mb-3">
          <span>
            You selected: <b>{{ formData.personnelName }}</b>
          </span>
        </v-col>
        <v-col cols="12">
          <v-data-table
            dense
            v-model="personnel"
            item-key="id"
            @click:row="selectPersonnel"
            :headers="searchHeaders"
            :items="personnelSearch"
            class="elevation-1 row-pointer"
            :hide-default-footer="true"
          ></v-data-table>
        </v-col>
      </v-row>
      <template v-slot:footer>
        <Button
          :label="'Ok'"
          :btnType="'Cancel'"
          @onClick="closePersonnelSearchModal"
        />
      </template>
    </Dialog>
    <v-row>
      <v-row v-if="data.length > 0">
        <v-col cols="10" class="mr-auto ml-auto ">
           <v-data-table
              :headers= 'dataHeader'
              :items="data"
             >
           </v-data-table>
        </v-col>
        <v-col cols="11 mt-5" class="d-flex justify-end">
          <Button
            :label="'Submit'"
            :btnType="'Submit'"
            @onClick="dialog = true"
            :disabled="!valid"
            :isLoading="isSaving"
            class="mr-4"
          />

          <Button :label="'Reset'" :btnType="'Cancel'" @onClick="onReset" />
        </v-col>
      </v-row>
    </v-row>
     <ConfirmationDialog :title="'Are you sure you want to submit?'" :isLoading="isSaving" :dialog="dialog" :btnTitle="'Yes'" @close="dialog = false" @btnAction="onSave"/>

     <PersonnelSearch :isLoading="isSaving" :dialog="perSearch" @selectPer="selectPersonnel"  @close="perSearch = false" @btnAction="onSave"/>
  </v-app>
</template>
<script>
import TextField from '@/components/ui/form/TextField.vue'
import Dialog from '@/components/ui/Dialog.vue'
import Button from '@/components/ui/form/Button.vue'
import SelectField from '@/components/ui/form/SelectField.vue'
import ConfirmationDialog from '@/components/ui/confirmationDialog.vue'
import PersonnelSearch from '@/components/ui/PersonnelSearchModal.vue'
import { employeeService, requestService } from '@/services'
import { CREATE_REQUEST } from '@/store/action-type'
import moment from 'moment'

export default {
  components: {
    TextField,
    Dialog,
    Button,
    SelectField,
    ConfirmationDialog,
    PersonnelSearch
  },
  data () {
    return {
      dialog: false,
      perSearch: false,
      alertMessage: '',
      alert: false,
      alertType: '',
      ItemType: null,
      selected: [],
      search: '',
      valid: false,
      isSaving: false,
      generateBtnLoading: false,
      personnelSearchLoading: false,
      training: '',
      days: '',
      formData: {
        personnelName: '',
        leaveType: '',
        training: '',
        RequestTypeId: 2,
        startDate: '',
        resumptionDate: '',
        days: 0,
        EmployeId: '',
        comment: ''
      },
      data: [],
      request: [],
      leaveTypes: [],

      personnelSearch: [],

      ppeTypes: [],
      searchHeaders: [
        {
          text: 'FullName',
          value: 'name'
        },
        {
          text: 'id',
          value: 'id'
        },
        {
          text: 'email',
          value: 'email'
        }
      ],
      selectedPersonnelHeader: [
        {
          text: 'Name',
          value: 'name'
        },
        { text: 'Actions', value: 'actions' }
      ],
      requestHeader: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Leave',
          value: 'leaveType'
        },

        {
          text: 'StartDate',
          value: 'startDate'
        },
        {
          text: 'Resumption Date',
          value: 'resumptionDate'
        },
        { text: 'Comment', value: 'comments' },
        { text: 'Actions', value: 'actions' }
      ],
      dataHeader: [
        {
          text: 'Leave Type',
          value: 'leaveType.name'
        },
        {
          text: 'Leave Days',
          value: 'leaveDays'
        },
        {
          text: 'Start Date',
          value: 'startDate'
        },
        {
          text: 'Resumption Date',
          value: 'resumptionDate'
        },
        {
          text: 'Comment',
          value: 'comment'
        }
      ],
      personnel: []
    }
  },
  watch: {
    // personnel: function () {
    //   if (this.personnel.length === 0) {
    //     this.onReset()
    //   } else {
    //     // this.formData.personnelName = this.personnel[0].name
    //   }
    // }
  },
  methods: {
    openPersonnelSearchModal () {
      this.$refs.personnelSearch.openModal()
    },
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
    },

    closePersonnelSearchModal () {
      this.personnelSearch = []
      this.$refs.personnelSearch.closeModal()
    },
    personnelSearchFunction () {
      if (this.search === '') {
        this.personnelSearch = []
        return
      }
      this.personnelSearchLoading = true
      employeeService.searchPersonnel(this.search).then(result => {
        this.personnelSearch = result.data
      }).catch(() => {
        this.showAlertMessage('Unable to load, Try again', 'error')
      }).finally(() => {
        this.personnelSearchLoading = false
      })
    },
    selectPersonnel (item) {
      // this.personnel.push({ name: `${item.name}`, id: item.id })
      this.formData.EmployeId = item.id
      this.formData.personnelName = item.name
      requestService.getRemainingLeaveDays(item.id).then(res => {
        this.days = res.data.toString()
      }).catch(() => {
        this.showAlertMessage('Failed to load Leave days', 'error')
      })
      this.search = ''
    },
    startDateSelected () {
      const data = { EmployeeId: this.formData.EmployeId, startDate: this.formData.startDate, days: this.formData.days }

      requestService.getResumptionDate(data).then(res => {
        this.formData.resumptionDate = moment(res.data).format('MM/DD/YYYY')
      }).catch(() => {
        this.showAlertMessage('Failed to load Resumption Date', 'error')
      })
    },
    removePersonnel (item) {
      let index = null
      for (let i = 0; i < this.personnel.length; i++) {
        if (this.personnel[i].id === item.id) {
          index = i
          break
        }
      }
      console.log(index)
      this.personnel.splice(index, 1)
    },
    add () {
      const newDays = parseInt(this.days) - parseInt(this.formData.days)
      if (newDays < 0) {
        this.showAlertMessage(`you have ${this.days} leave days left`, 'error')
        setTimeout(() => {
          this.alert = false
        }, 5000)
        return
      }
      this.data.push({ leaveDays: this.formData.days, startDate: this.formData.startDate, resumptionDate: this.formData.resumptionDate, comment: this.formData.comment, leaveType: this.formData.leaveType })
      this.days = '' + newDays
      this.personnel.push({ name: `${this.formData.personnelName}`, id: this.formData.personnelName })
      // console.log('days', typeof this.days)
      // console.log('leave days', typeof this.formData.days)
    },
    removeRequest (item) {
      let index = null
      for (let i = 0; i < this.request.length; i++) {
        if (this.request[i].id === item.id) {
          index = i
          break
        }
      }
      console.log(index)
      this.request.splice(index, 1)
    },
    generateRequest () {
      this.generateBtnLoading = true
      const leaveRequesters = []

      this.personnel.forEach(per => {
        leaveRequesters.push({
          EmployeId: per.id,
          RequestTypeId: 2,
          RequesStartDate: this.formData.startDate
        })
      })

      requestService.validateRequests(leaveRequesters).then(result => {
        if (result.data.length === 0) {
          this.personnel.forEach(per => {
            this.request.push({
              name: per.name,
              id: per.id,
              leaveType: this.formData.leaveType.name,
              leaveTypeId: this.formData.leaveType.id,
              startDate: this.formData.startDate,
              LeaveDays: this.formData.days,
              resumptionDate: this.formData.resumptionDate,
              comment: per.comment
            })
          })
        }
      }).catch(error => {
        console.log(error)
      })

      this.generateBtnLoading = false

      console.log(this.request)
    },
    onSave () {
      let data = []
      this.data.filter((element) => {
        data.push(
          { EmployeeId: this.formData.EmployeId,
            RequestTypeId: 2,
            StartDate: element.startDate,
            ResumptionDate: element.resumptionDate,
            leaveTypeId: element.leaveType.id,
            LeaveDays: element.leaveDays,
            Comments: element.comment }
        )
      })
      this.isSaving = true

      console.log('data', data)

      this.$store
        .dispatch(CREATE_REQUEST, { type: 'leave', data })
        .then(() => {
          this.showAlertMessage('Request creation successful', 'success')
          this.onReset()
        })
        .catch(error => {
          console.log(error)
          this.showAlertMessage('Request creation failed', 'error')
        })
        .finally(() => {
          this.isSaving = false
          this.dialog = false
          this.data = []
        })
    },
    onReset () {
      this.personnel = []
      this.request = []
      this.data = []
      this.formData.personnelName = ''
      this.formData.leaveType = ''
      this.formData.training = ''
      this.formData.RequestTypeId = 2
      this.formData.startDate = ''
      this.formData.resumptionDate = ''
      this.formData.days = 0
      this.formData.EmployeId = ''
      this.formData.comment = ''
      this.$refs.leaveRequest.reset()
    }
  },
  mounted () {
    requestService
      .getLeaveTypes()
      .then(result => {
        this.leaveTypes = result.data
      })
      .catch(error => {
        console.log(error)
      })
  }
}
</script>
<style scoped>
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.btn-style {
  color: rgba(0, 0, 0, 0.6);
  text-align: left;
}
.text-style {
  text-transform: capitalize;
}
.input {
  border: 1px solid grey;
  height: 30px;
  padding-left: 10px;
}
.row-pointer {
  cursor: pointer;
}
</style>
